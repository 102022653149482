/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import { initReactI18next } from 'react-i18next';

import Utils from '@/utils';
import i18n from 'i18next';

const {
  Common: { getLang },
} = Utils;

/**
 * 获取 I18n 资源
 * @param {*} langArr 语言数组
 * @param {*} fileNames 文件名数组
 * @param {*} fileNameMap 文件名映射，用于处理文件不存在的情况
 * @param {*} disabledFileNameLangs 禁用文件的语言
 * @returns
 */
const getI18nResources = (
  langArr = [],
  fileNames = [],
  fileNameMap = {},
  disabledFileNameLangs = {}
) => ({
  ...langArr.reduce((acc, lang) => {
    const newAcc = { ...acc };
    newAcc[lang] = {
      translation: {
        ...require(`../locales/common/${lang}.json`),
        ...fileNames.reduce((acc2, fileName) => {
          const newAcc2 = { ...acc2 };
          if (
            disabledFileNameLangs[fileName] &&
            disabledFileNameLangs[fileName].includes(lang)
          ) {
            // 禁用文件的语言
          } else {
            newAcc2[fileName] = require(`../locales/${
              fileNameMap[fileName] || fileName
            }/${lang}.json`);
          }
          return newAcc2;
        }, {}),
      },
    };
    return newAcc;
  }, {}),
});

i18n.use(initReactI18next).init({
  resources: getI18nResources(
    [
      'ar',
      'de',
      'en',
      'es_419',
      'es',
      'fr',
      'in',
      'it',
      'ja',
      'ko',
      'ms',
      'pl',
      'pt_br',
      'pt',
      'ru',
      'th',
      'tr',
      'vi',
      'zh_hk',
      'zh',
    ],
    ['u200', 'u260', 'u200Lite', 'u300'],
    { u200Lite: 'u260' },
    {}
  ),
  lng: getLang(), // 设置语言
  fallbackLng: 'en', // 默认语言设置，当其他语言没有的情况下，使用 fallbackLng 作为默认语言

  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});

export default i18n;
