/* eslint-disable import/order */
/* eslint-disable sort-imports */
/* eslint-disable import/no-duplicates */
/* eslint-disable import-helpers/order-imports */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';
import { ThunkAction } from 'redux-thunk';

import { LoginAction } from './reducers/login';

// 参数1：reducer
// 参数2：指定store的初始值
// 参数3：指定中间件
const store = createStore(
  reducer,
  {},
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

type RootAction = LoginAction;
export type RootThunkAction = ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  RootAction
>;

export default store;
