// 引入crypto-js包
import CryptoJS from 'crypto-js';

/**
 * 加密算法使用AES-128，模式为：CBC，补码方式为：PKCS7Padding
 * 密钥(128位)为：V1P8bXq95Sw4VDpu   初始化向量：PnHta8uuxjcLRjTG
 */
const AES_KEY = 'V1P8bXq95Sw4VDpu';
const AES_IV = 'PnHta8uuxjcLRjTG';

/**
 * AES加密
 * @param {any} word 加密数据
 */
export const AESEncrypt = (word: any) => {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY);
  const iv = CryptoJS.enc.Utf8.parse(AES_IV);
  let srcs: any;
  if (typeof word === 'string') {
    srcs = word;
  } else if (typeof word === 'object') {
    // 对象格式的转成json字符串
    srcs = CryptoJS.enc.Utf8.parse(word);
  }
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString();
};

/**
 * AES解密
 * @param {any} word 解密数据
 */
export const AESDecrypt = (word: any) => {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY);
  const iv = CryptoJS.enc.Utf8.parse(AES_IV);
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
};

export default {
  AESEncrypt,
  AESDecrypt,
};
