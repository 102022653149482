/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';

import { getUrlParams } from './common';

console.log(process.env.REACT_APP_ENV, process.env.REACT_APP_API_URL);
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

// 配置请求拦截器
instance.interceptors.request.use(
  (config: any) => {
    const { token } = getUrlParams();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError<{ message: string }>) =>
    // 对错误做点什么
    Promise.reject(error)
);

// 配置响应拦截器
instance.interceptors.response.use(
  (response) => {
    const { data } = response;
    return data;
  },
  (error) => {
    let info = {};

    if (!error.response) {
      info = {
        code: 500,
        message: '网络异常',
      };
    } else {
      const { status, statusText, data } = error.response;
      info = {
        code: status,
        data,
        message: statusText,
      };
    }
    return Promise.reject(info);
  }
);

export default instance;
