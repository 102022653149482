export const mobileRegex = /^1[3-9]\d{9}$/;
export const smsCodeRegex = /^\d{6}$/;

export const serviceAgreementURL =
  'https://document.uwant.net.cn/serviceAgreement/zh/service_agreement_zh.html';
export const privacyPolicyURL =
  'https://document.uwant.net.cn/privacyPolicy/zh/privacy_policy_zh.html';

// android 应用宝跳转地址
const androidUwantHomeDownloadUrl =
  'https://a.app.qq.com/o/simple.jsp?pkgname=com.uwant.home';
// ios app store 地址
const iOSUwantHomeDownloadUrl = 'itms-apps://itunes.apple.com/app/id1611137020';

// 海外ios app store 地址
const iOSENUwantHomeDownloadUrl =
  'itms-apps://itunes.apple.com/app/id1622793082';

// 海外谷歌商店地址
const androidENUwantHomeDownloadUrl =
  'https://play.google.com/store/apps/details?id=com.uwant.smart';

const productionURL = 'https://uiot-dev.uwant.net.cn'; // 测试
// const productionURL = 'https://uiot.uwant.net.cn'; // 生产

export default {
  mobileRegex,
  smsCodeRegex,
  serviceAgreementURL,
  privacyPolicyURL,
  productionURL,
  androidUwantHomeDownloadUrl,
  iOSUwantHomeDownloadUrl,
  iOSENUwantHomeDownloadUrl,
  androidENUwantHomeDownloadUrl,
};
